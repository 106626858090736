<template>
  <b-row class="match-height">
    <b-col lg="12">
      <LessonDone />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import LessonDone from '@/components/student/lessonApply/LessonDone.vue'

export default {
  components: {
    BRow,
    BCol,
    LessonDone,
  },
  data() {
    return {
    }
  },
}
</script>
