<template>
  <div>
    <h2>学習履歴一覧</h2>
    <b-tabs align="let">
      <b-tab>
        <template #title>
          <feather-icon icon="BookOpenIcon" />
          <span class="iconSmartPhone">学習履歴一覧</span>
        </template>
        <LessonDoneList />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="BookOpenIcon" />
          <span class="iconSmartPhone">お気に入り(サービス)</span>
        </template>
        <FavoriteLessonList />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="BookOpenIcon" />
          <span class="iconSmartPhone">お気に入り(講師)</span>
        </template>
        <FavoriteTeacherList />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import LessonDoneList from '@/components/student/lessonApply/LessonDoneList.vue'
import FavoriteLessonList from '@/components/student/favorite/FavoriteLessonList.vue'
import FavoriteTeacherList from '@/components/student/favorite/FavoriteTeacherList.vue'

export default {
  components: {
    BTabs,
    BTab,
    LessonDoneList,
    FavoriteLessonList,
    FavoriteTeacherList,
  },
}
</script>

<style scoped>
</style>
