<template>
  <div>
    <b-card>
      <div class="">
        「いいね」を押した講師の一覧を確認できます。
      </div>
    </b-card>
    <div class="card_ mt-3">
      <h3
        v-if="fields.length === 0"
      >
        表示するデータはありません。
      </h3>
      <b-card
        v-for="(item, index) in fields"
        v-else
        :key="index"
        title=""
        variant="outline-primary"
        class="card_"
      >
        <h3 class="">
          <b-row>
            <b-col xl="6">
              <!-- <feather-icon
                icon="UsersIcon"
                size="24"
                class="text-primary"
              /> -->
              <b-avatar
                :src="item.lessonPic"
              />
              {{ item.lessonName }}
            </b-col>
            <b-col
              xl="6"
              class="text-right"
            >
              <span class="text-primary"><b>{{ Number(item.coin).toLocaleString() }}</b></span><small>コイン</small>
              <!-- {{ item.coin.toLocaleString() }} <small>コイン</small> -->
            </b-col>
          </b-row>
        </h3>
        <span class="day_">
          {{ item.startTime | formatDate }}
        </span>
        <span class="time_ text-primary">
          <b>{{ item.lessonDdateLabel }}</b>
        </span>
        <b-badge
          variant="light-danger"
          class="ml-1"
        >
          コメント：{{ item.isComment }}
        </b-badge>
        <b-badge
          v-if="item.lessonCategory"
          variant="light-primary"
          class="ml-1"
        >
          {{ item.lessonCategory.labelName }}
        </b-badge>
        <b-badge
          variant="light-success"
          class="ml-1"
        >
          受講生数：1人
        </b-badge>
        <div class="text-right">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="submit"
            variant="primary"
            size="sm"
          >
            確認する
          </b-button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  //  BButton,
  BCard, BRow, BCol, BButton, BAvatar, BBadge,
  //  BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import field from '@/components/student/lessonApply/test/受講生_実行済みレッスン.json'
import header from '@/components/conf/student/学習PF_受講生_実行済みヘッダー.json'
import getAllDataList from '@/firestore/lesson/getTeacherLessonList'
import dateformate from '@/components/js/filter/formatDateDayOnly'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BAvatar,
    BBadge,
    // BCardText,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatDate(val) {
      return dateformate(val)
    },
  },
  props: {
    profileDic: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      header,
      field,
      fields: [],
      select: [
        '受講前',
        '受講済',
        '取消',
      ],
      selectB: [
        'eラーニング',
        'オンラインレッスン',
        'オンラインイベント',
        'オフラインイベント',
      ],
      selectC: [
        'コメントあり',
        'コメントなし',
      ],
      selected: [],
      selectedB: [],
      lessonList: [],
    }
  },
  watch: {
    selected: {
      handler() {
        this.sort(this.selected)
      },
      immediate: true,
    },
    selectedB: {
      handler() {
        this.sort(this.selectedB)
      },
      immediate: true,
    },
  },
  async mounted() {
    const response = await getAllDataList()
    if (response.status === 'success') {
      // window.console.log('success', response.status, response.data)
      this.lessonList = response.data
      if (this.selected.length === 0) this.fields = this.lessonList
      // this.status = 1
    } else {
      window.console.log('error', response.msg)
    }
  },
  methods: {
    edit() {
      this.$router.push({ path: 'teacher-reward-edit' })
    },
    sort(val) {
      /* eslint-disable */
      let listS = []
      // window.console.log('memo', val)
      if (val.length > 0) {
        for (let i in val) {
          let item = val[i]
          // window.console.log('🌻', val.length, item)
          // ここでは大元のデータであるfieldを1件ずつ探す必要がある。【OR検索🔥】
          // これをfieldsにしてしまうと1回目でフィルタリングされたデータが対象となる。
          for (let d in this.field) {
            const data = this.field[d]

            // 複数のチェックボックスでもv-modelで共有している→該当するものをとにかく見つけ出してリストに入れる
            if (data.status === item && listS.findIndex(v => v.num === data.num) === -1) listS.push(data)
            if (data.category === item && listS.findIndex(v => v.num === data.num) === -1) listS.push(data)
            if (data.comment === item && listS.findIndex(v => v.num === data.num) === -1) listS.push(data)
          }
        }
        this.fields = listS
      } else {
        this.fields = this.field
      }
      /* eslint-enable */
    },
    reset() {
      this.selected = []
      this.fields = this.field
    },
  },
}
</script>
<style scoped>
.card_ {
  width: 100%;
  /* margin: 1% 1%; */
}
.day_ {
  font-size: 1rem;
  font-weight: 600;
}
.time_ {
  font-size: 1.4rem;
}
</style>
